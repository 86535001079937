import React, { useState, useEffect } from 'react'
import { useCookies } from "react-cookie"; // クッキー処理

export function BasicAuthentication (layoutData) {

const basicAuthentication = true; // true:Basic認証オン、false:Basic認証オフ
const basicPassword = 'milton1010'; // Basic認証用のパスワードをここに設定

const [password, setPassword] = useState(''); // 入力パスワード取得用フック
const [isLoggedIn, setIsLoggedIn] = useState(false); // ログイン状態管理用フック
const [cookies, setCookie] = useCookies(['pass']); // Cookie保存用フック
const [cookieValue, setCookieValue] = useState(''); // Cookie取得用フック
const [isReady, setIsReady] = useState(false); // 処理状態管理用フック

useEffect(() => {
	// Cookieの値取得用関数
	const getCookieValue = () => {
	const cookiesArray = document.cookie.split(';');
	const cookie = cookiesArray.find(cookie => cookie.includes('pass='));
	return cookie ? cookie.split('=')[1] : '';
	};

	// Cookieの値を取得して設定
	const cookieValue = getCookieValue();
	setCookieValue(cookieValue);

	// cookieValueが保存されている場合ログイン状態へ遷移
	if (cookieValue === basicPassword) {
	setIsLoggedIn(true);
	}
	setIsReady(true); // 追加
	}, []);

	// 初回ログイン処理
	const handlePasswordSubmit = (e) => {
		e.preventDefault();
		if (password === basicPassword) {
		setIsLoggedIn(true);
		} else {
		alert('パスワードが正しくありません。');
		}
	};

	// ログイン成功時cookieにpassを保存する
	useEffect(() => {
		if (isLoggedIn) {
		// クッキー設定
		const expires = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000); // 有効期限を30日に設定
		const path = '/';
		setCookie('pass', basicPassword, { expires, path }); // 上書き
		}
	}, [isLoggedIn, setCookie]);

	useEffect(() => {
		// ページがロードされた後直後に処理を実行する
		setTimeout(() => {
		  const hash = window.location.hash; // URLのハッシュ部分を取得
	
		  if (hash) {
			const targetElement = document.getElementById(hash.substring(1)); // #を除いたハッシュ部分を使って対象の要素を取得
			if (targetElement) {
			//   targetElement.scrollIntoView({ behavior: 'smooth' }); // スクロール遅め
			//   targetElement.scrollIntoView({ behavior: 'auto' }); // スクロール早め
			  targetElement.scrollIntoView({ behavior: 'auto', block: 'start' }); // 該当のidに移動した状態で
			}
		  }
		}, 0);
	  }, []);
	

	// 処理未完了状態は何も表示しない
  if (!isReady) { 
    return null;
  } 
  // -------------------------------------------------------------------------

  // Basic認証オンの時の処理
  if (basicAuthentication) {
    return (
      <div>
        {isLoggedIn ? layoutData : (
          <div className="flex justify-center items-center h-screen">
            <form onSubmit={handlePasswordSubmit}>
              <input
                className="w-[300px] text-center border border-solid border-[black] px-3 py-2 mb-5"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="パスワードを入力してください"
              />
              <button className="block mx-auto my-0" type="submit">表示する</button>
            </form>
            
          </div>
        )}
      </div>
    );
  // Basic認証オフの時の処理
  } else {
    return layoutData
  }
}

